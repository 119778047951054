body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style-type: none;
}

.sidebar li {
  position: relative;
  margin-left: 24px;
  padding-left: 0;
}

.sidebar li::before {
  content: '\2022';
  color: inherit;
  font-size: 24px;
  font-weight: bold;
  display: inline-block;
  position: absolute;
  width: 1em;
  transform: translateX(-50%) translateY(-55%);
  left: -8px;
  top: 50%;
}

/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #c9c9c9 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 16px;
}

*::-webkit-scrollbar-track {
  background: #ffffff00;
}

*::-webkit-scrollbar-thumb {
  background-color: #c9c9c9;
  border-radius: 8px;
  border: 4px solid #ffffff;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #333;
}
